import React, { Ref } from 'react'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import Container from '../primitives/grid/container'

// import BookIcon from '../../images/book-icon.svg';
// import ContrastIcon from '../../images/contrast-icon.svg';
// import EnglishIcon from '../../images/english-icon.svg';
// import GermanIcon from '../../images/english-icon.svg';
import SearchIcon from '../../images/search.svg'
import ColSet from '../primitives/grid/col-set'
import { StyledIconButtonColored } from '../primitives/styled-icon-button'
import ScrollEffect from '../animations/components/scroll-effect'
import {
    DropdownInsert,
    DropdownTrigger,
    MegaMenu,
    MegaMenuDropdown,
    MenuItem,
    MenuList,
    SubMenu,
} from '../primitives/mega-menu'
import wordpressFlatMenuToHierarchical, {
    ParsedMenuItem,
} from '../../utils/wordpress-flat-menu-to-hierarchical'
import StyledLink from '../primitives/styled-link'
import StyledSearchInput from '../primitives/styled-search-input'
import VisuallyHidden from '../primitives/visually-hidden'
import CloseIcon from '../../images/close-icon.svg'
import NotfallIcon from '../../images/notfall-icon.svg'
import ArrowUPRightIcon from '../../images/arrow-up-right-regular.svg'
import getLangAndTranslation from '../../utils/get-lang-and-translation'
import StyledButton from '../primitives/styled-button'
import { useSearchTermState } from '../../utils/use-search-builder'
import { useDraggable } from '../../utils/use-draggable-scroll'
import { GatsbyImageSVGFallback } from '../primitives/image/gatsby-image-svg-fallback'
import { useContrast } from '../theme/contrast-context'

interface DesktopHeaderProps {
    language?: {
        slug: 'en' | 'de'
    }
    translations?: {
        link: string
    }[]
    headerRef?: Ref<HTMLDivElement | null>
}

const DesktopHeader = ({
    language,
    translations,
    headerRef,
}: DesktopHeaderProps) => {
    const [openMenu, setOpenMenu] = React.useState<string | undefined>()

    const { switchTheme } = useContrast()

    const { lang, translation } = getLangAndTranslation({
        language,
        translations,
    })
    const menuData = useStaticQuery(graphql`
        {
            englishMenu: wpMenu(locations: { eq: PRIMARY_MENU___EN }) {
                menuItems {
                    nodes {
                        label
                        url
                        id
                        parentId
                    }
                }
            }
            deutschMenu: wpMenu(locations: { eq: PRIMARY_MENU }) {
                menuItems {
                    nodes {
                        label
                        url
                        id
                        parentId
                    }
                }
            }
            wp {
                siteOptions {
                    websiteFunktionen {
                        enableEmergency
                        enableSearch
                    }
                    seiteLogos {
                        logos {
                            image {
                                localFile {
                                    publicURL
                                }
                            }
                            link
                        }
                    }
                }
            }
        }
    `)

    const rawMenusEn = menuData?.englishMenu?.menuItems?.nodes
    const rawMenusDe = menuData?.deutschMenu?.menuItems?.nodes
    const siteLogos = menuData?.wp?.siteOptions?.seiteLogos?.logos
    const enableEmergency =
        menuData?.wp?.siteOptions?.websiteFunktionen?.enableEmergency
    const enableSearch =
        menuData?.wp?.siteOptions?.websiteFunktionen?.enableSearch

    const menuItems = wordpressFlatMenuToHierarchical(
        lang === 'en' && rawMenusEn?.length > 0 ? rawMenusEn : rawMenusDe
    )
    
    const [searchQuery, setSearchQuery] = useSearchTermState()

    const ref = React.useRef<HTMLDivElement>(null)
    const { events } = useDraggable(ref as any)

    return (
        <div
            css={{
                width: '100%',
                border: 'none',
                height: 'auto',
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: 'var(--color-gray-white)',
                boxShadow: '0rem 0.1875rem 1.25rem rgb(0 0 0 / 8%)',
            }}
        >
            <Container>
                <div
                    css={{
                        width: '100%',
                        height: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div
                        css={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            css={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                paddingTop: '20px',
                                borderBottom:
                                    '1px solid var(--color-gray-mittelgrau)',
                                paddingBottom: '18px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <ScrollEffect
                                runOnce={false}
                                inViewProperties={{
                                    x: [-10, 0],
                                    opacity: [0, 1],
                                }}
                                css={{
                                    overflow: 'auto',
                                    '& > div': {
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        overflow: 'auto',
                                    },
                                }}
                            >
                                {siteLogos?.map((logo, index) => (
                                    <Link
                                        key={index}
                                        to={lang === 'en' ? '/en' : logo.link}
                                        css={{ flexShrink: 0 }}
                                    >
                                        <GatsbyImageSVGFallback
                                            src={logo.image.localFile.publicURL}
                                            css={{
                                                width: 'fit-content',
                                                maxWidth:
                                                    siteLogos.length == 1
                                                        ? '240px'
                                                        : '160px',
                                                marginRight: '62px',
                                                height: '45px',
                                            }}
                                        />
                                    </Link>
                                ))}
                            </ScrollEffect>
                            <div
                                css={{
                                    width: 'fit-content',
                                    height: 'auto',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {/* <ColSet breakpoints={{ dt: { between: 12 } }} css={{ alignItems: 'center' }}>
                                    <ScrollEffect
                                        inViewProperties={{
                                            opacity: [0, 1],
                                            y: [5, 0],
                                        }}
                                        inViewDelay={0.05}
                                        runOnce={false}
                                    >
                                        <StyledIconButton css={{ marginTop: '2px' }}>
                                            <BookIcon />
                                            <VisuallyHidden>
                                                Leichte Sprache
                                            </VisuallyHidden>
                                        </StyledIconButton>
                                    </ScrollEffect>
                                    <ScrollEffect
                                        inViewProperties={{
                                            opacity: [0, 1],
                                            y: [5, 0],
                                        }}
                                        inViewDelay={0.1}
                                        runOnce={false}
                                    >
                                        <StyledIconButton css={{ marginTop: '1px' }} onClick={switchTheme}>
                                            <ContrastIcon css={{ marginLeft: '1px' }} />
                                            <VisuallyHidden>
                                                Kontrast
                                            </VisuallyHidden>
                                        </StyledIconButton>
                                    </ScrollEffect>
                                    <ScrollEffect
                                        inViewProperties={{
                                            opacity: [0, 1],
                                            y: [5, 0],
                                        }}
                                        inViewDelay={0.15}
                                        runOnce={false}
                                    >
                                        <StyledIconButtonLink to={translation} css={{ marginTop: '2px' }}>
                                            {lang === 'de' && <EnglishIcon css={{ marginLeft: '1px' }} />}
                                            {lang === 'en' && <GermanIcon css={{ marginLeft: '1px' }} />}
                                            <VisuallyHidden>
                                                {lang}
                                            </VisuallyHidden>
                                        </StyledIconButtonLink>
                                    </ScrollEffect>
                                </ColSet> */}
                                {enableSearch && (
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault()
                                            navigate(
                                                `/suche/?query=${searchQuery}`
                                            )
                                        }}
                                    >
                                        <div
                                            css={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <StyledSearchInput
                                                value={searchQuery}
                                                onChange={e => {
                                                    // @ts-ignore
                                                    setSearchQuery(
                                                        e?.target?.value
                                                    )
                                                }}
                                                name="search"
                                                placeholder="Suche"
                                            />
                                            <StyledIconButtonColored
                                                type="submit"
                                                css={{
                                                    marginLeft: '9px',
                                                    color: '#47474A',
                                                }}
                                            >
                                                <SearchIcon
                                                    css={{ width: '20px' }}
                                                />
                                                <VisuallyHidden>
                                                    Suche
                                                </VisuallyHidden>
                                            </StyledIconButtonColored>
                                        </div>
                                    </form>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <MegaMenu
                value={openMenu}
                onValueChange={e => {
                    setOpenMenu(e)
                }}
            >
                <MegaMenuDropdown>
                    <MenuList>
                        <Container
                            css={{
                                width: '100%',
                                height: 'auto',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                maxWidth: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                zIndex: '20',
                                '@media (max-width: 767px)': {
                                    maxWidth: 'auto',
                                    justifyContent: 'flex-start',
                                },
                            }}
                        >
                            <ColSet
                                breakpoints={{ dt: { between: 45 } }}
                                css={{
                                    width: '100%',
                                    display: 'flex',
                                    flexWrap: 'nowrap',
                                    overflowX: 'auto',
                                    overflowY: 'hidden',
                                    alignItems: 'center',
                                    flexShrink: '1',
                                    paddingTop: '18px',
                                    height: '66px',
                                    justifyContent: 'flex-start',
                                    WebkitOverflowScrolling: 'touch',
                                    '&::-webkit-scrollbar': {
                                        height: '10px',
                                        borderTop: '9px solid #fff',
                                        backgroundColor:
                                            'var(--color-gray-almostwhite)',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        height: '10px',
                                        borderTop: '9px solid #fff',
                                        backgroundClip: 'padding-box',
                                        backgroundColor:
                                            'var(--color-blue-mittelblau)',
                                    },
                                }}
                                {...events}
                            >
                                {menuItems.map((item, idx) => {
                                    if (item.childMenu?.length > 0) {
                                        return (
                                            <ScrollEffect
                                                key={idx}
                                                runOnce={false}
                                                inViewProperties={{
                                                    opacity: [0, 1],
                                                }}
                                                inViewDelay={0.05 * idx + 0.1}
                                            >
                                                <MegaMenuItem
                                                    {...item}
                                                    headerRef={headerRef}
                                                    onOpen={() => {
                                                        setOpenMenu(item.label)
                                                    }}
                                                    onClose={() => {
                                                        setOpenMenu('')
                                                    }}
                                                    value={item.label}
                                                />
                                            </ScrollEffect>
                                        )
                                    }
                                    return (
                                        <ScrollEffect
                                            key={idx}
                                            runOnce={false}
                                            inViewProperties={{
                                                opacity: [0, 1],
                                            }}
                                            inViewDelay={0.05 * idx + 0.1}
                                        >
                                            <MegaMenuLink
                                                {...item}
                                                onOpen={() => {
                                                    setOpenMenu('')
                                                }}
                                            />
                                        </ScrollEffect>
                                    )
                                })}
                            </ColSet>
                            {enableEmergency && (
                                <StyledButton
                                    icon={<NotfallIcon />}
                                    to="/notfall"
                                    css={{
                                        width: 'fit-content',
                                        maxWidth: '20%',
                                        flexShrink: 0,
                                        marginLeft: '20px',
                                        fontWeight: 600,
                                    }}
                                    className="p2"
                                    theme="red"
                                >
                                    Notfall
                                </StyledButton>
                            )}
                        </Container>
                    </MenuList>
                </MegaMenuDropdown>
            </MegaMenu>
        </div>
    )
}

const DropdownLinkStyle: React.FC<Omit<
    ParsedMenuItem,
    'childMenu' | 'id' | 'parentId' | 'url'
> & {
    onOpen?: () => void
    showArrow?: boolean
    url?: string
}> = ({ label, url, onOpen, showArrow = false }) => (
    <StyledLink
        to={url}
        className="p2"
        onClick={onOpen}
        css={{
            color: 'var(--color-gray-schwarz)',
            paddingBottom: '19px',
            transition: '0.3s',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            '[data-state="open"] &': {
                color: 'var(--color-blue-mittelblau)',
            },
            '&:hover': {
                color: 'var(--color-blue-mittelblau)',
            },
        }}
    >
        <span
            css={{
                width: 'auto',
                fontWeight: 'bold',
                marginRight: '0px',
                flex: 'none',
                whiteSpace: 'nowrap',
                '@media (max-width: 1150px)': {
                    fontSize: '15px',
                },
            }}
        >
            {label}
        </span>
        {showArrow && (
            <ArrowUPRightIcon
                css={{
                    width: '11px',
                    objectFit: 'cover',
                    marginLeft: '6px',
                }}
            />
        )}
    </StyledLink>
)

const MegaMenuLink = ({
    label,
    url,
    onOpen,
}: ParsedMenuItem & { onOpen: () => void }) => (
    <DropdownLinkStyle
        label={label}
        url={url}
        onOpen={onOpen}
        showArrow={Array.from(url)[0] !== '/' && Array.from(url)[0] !== '#'}
    />
)

const MegaMenuItem = ({
    childMenu,
    label,
    url,
    value,
    onOpen,
    onClose,
    headerRef,
}: ParsedMenuItem & {
    value: string
    onOpen: () => void
    onClose: () => void
    headerRef?: Ref<HTMLDivElement | null>
}) => (
    <MenuItem value={value}>
        <DropdownTrigger>
            <DropdownLinkStyle label={label} onOpen={onOpen} />
            <DropdownInsert
                headerRef={headerRef}
                css={{
                    boxShadow: '0rem 1.1875rem 1.25rem -1rem rgb(0 0 0 / 8%)',
                }}
            >
                <Container>
                    <SubMenu>
                        <MenuList>
                            <div
                                css={{
                                    width: '100%',
                                    display: 'flex',
                                    paddingTop: '27px',
                                    flexDirection: 'column',
                                    paddingBottom: '23px',
                                }}
                            >
                                <CloseIcon
                                    css={{
                                        width: '14px',
                                        objectFit: 'cover',
                                        marginLeft: 'auto',
                                        marginBottom: '14px',
                                    }}
                                />
                                <div
                                    css={{
                                        width: '100%',
                                        display: 'grid',
                                        gridGap: '0 20px',
                                        position: 'relative',
                                        flexWrap: 'wrap',
                                        alignItems: 'flex-start',
                                        justifyContent: 'space-between',
                                        gridTemplateColumns: 'repeat(3, 1fr)',
                                        gridTemplateRows: `repeat(${Math.ceil(
                                            childMenu.length / 3
                                        )}, auto)`,
                                        gridAutoFlow: 'column',
                                    }}
                                >
                                    {childMenu.map(({ label, url }, index) => (
                                        <Link
                                            to={url}
                                            onClick={() => {
                                                onClose()
                                            }}
                                            css={{
                                                gridColumn:
                                                    childMenu.length % 3 !==
                                                        0 &&
                                                    childMenu.length -
                                                        Math.ceil(
                                                            childMenu.length / 3
                                                        ) <
                                                        index
                                                        ? '3 / 4'
                                                        : 'auto',
                                                marginBottom: '16px',
                                            }}
                                            className="p2"
                                            key={index}
                                        >
                                            <span
                                                css={{
                                                    fontStyle: 'normal',
                                                    fontWeight: 600,
                                                    height: 'auto',
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    borderBottom:
                                                        '1px solid var(--color-gray-mittelgrau)',
                                                    flexDirection: 'column',
                                                    paddingBottom: '11px',
                                                    paddingTop: '11px',
                                                    textAlign: 'left',
                                                    transition: '0.3s',
                                                    color: '#636A71',
                                                    '&:hover': {
                                                        color:
                                                            'var(--color-blue-mittelblau)',
                                                        borderBottom:
                                                            '1px solid var(--color-blue-mittelblau)',
                                                        '& span': {
                                                            transform:
                                                                'translateX(5px)',
                                                        },
                                                    },
                                                }}
                                            >
                                                {label}
                                            </span>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </MenuList>
                    </SubMenu>
                </Container>
            </DropdownInsert>
        </DropdownTrigger>
    </MenuItem>
)

export default DesktopHeader
